@tailwind base;
@tailwind components;
@tailwind utilities;

.input {
  @apply bg-white
		border
		border-solid
		border-primary1
		focus:border-primary1-light
		font-main
		hover:border-primary1-light
		placeholder-opacity-80
		p-2
		rounded
		w-full;
}

.input[readonly],
.input[disabled] {
  @apply bg-greySoft
		border-greySoft
		focus:border-greySoft
		hover:border-greySoft;
}

.input[aria-invalid] {
  @apply border-hardKO;
}

.textarea {
  @apply bg-white
		border
		border-solid
		border-primary1
		focus:border-primary1-light
		font-main
		h-15
		hover:border-primary1-light
		placeholder-opacity-80
		p-2
		rounded
		w-full;
}

/* Leaflet */
.leaflet-popup .leaflet-popup-content {
	@apply m-0;
	/* Show the loader properly */
	@apply relative;
	min-height: 120px;
	min-width: 120px;
}

.leaflet-popup .leaflet-popup-content-wrapper {
	@apply p-0 rounded-xl overflow-hidden w-55 desktop:w-70;
}

.leaflet-popup .leaflet-popup-tip {
	/* Removes native leaflet popup triangle below the content
		 https://stackoverflow.com/a/51457598/14707543
	*/
	background: rgba(0, 0, 0, 0) !important;
	box-shadow: none !important;
}

.hasDrawer .leaflet-bottom {
  @apply mb-12;
  @apply desktop:mb-0;
}

.leaflet-container .leaflet-top.leaflet-left {
  @apply flex flex-col;
}

.leaflet-container .leaflet-control-zoom {
  @apply order-first;
}
